import * as React from "react"
import * as THREE from 'three';
import Layout from "../components/Layout";
import { Loading } from "../components/Loading";

import '../styles/index.scss';

import profile from '../images/profile.jpg'
import { Link } from "gatsby";

// markup
const AboutPage = () => {

  React.useEffect(() => {

  }, [])

  return (
    <Layout>
      <nav style={{ margin: '3em auto 0 auto' }}>
          <Link to='/'>Main</Link>
          <Link to='/work'>Work</Link>
          <Link to='/contact'>Contact</Link>
      </nav>
        <main>
            <header>
                <h1>About me</h1>
            </header>

            <article className="me-info">
                <img src={profile} alt="Avatar"/>
                <p>Technology enthusiast, who started his programming journey with static websites creation and now developing different applications 
                    across wide range of technologies. Fascinated about big data solutions and distributed systems. Likes to work on both frontend and 
                    backend features. Despite every day job, working on several side projects – commercially and for fun. </p>
            </article>

            <section>
                {/* <h2>Skills</h2> */}
                <div className="me-skills">
                    <section>
                        <h4>Frontend</h4>

                        <article>
                            <div class="skill">
                                <p>Javascript</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>React</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>HTML &amp; CSS</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>RWD</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>GatsbyJS</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>Ionic (React)</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>React Native</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>jQuery</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>Bootstrap</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>


                        </article>

                    </section>

                    <section>
                        <h4>Backend</h4>

                        <article>
                            <div class="skill">
                                <p>.NET</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>NodeJS</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>Python</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>Java</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            
                            <div class="skill">
                                <p>PHP</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                        </article>
                    </section>

                    <section>

                    <h4>Others</h4>

                        <article>
                            <div class="skill">
                                <p>MySQL</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>MS SQL</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>Firebase</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>MongoDB</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>
                        </article>

                        <article>
                            <div class="skill">
                                <p>Apache Kafka</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>KSQL</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>Kafka Streams</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>Docker</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                            <div class="skill">
                                <p>Wordpress</p>
                                <div class="rating-container">
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating full"></div>
                                    <div class="rating empty"></div>
                                    <div class="rating empty"></div>
                                </div>
                            </div>

                        </article>
                    </section>

                </div>

                
            </section>

        </main>

    </Layout>
  )

}

export default AboutPage
